import React, { forwardRef } from 'react';
import * as RadixSelect from '@radix-ui/react-select';

import { mapClassNamesToSlots, TVStyleProps } from '../../../style-system';
import { ChevronDownIcon, ChevronSelectorVerticalIcon, ChevronUpIcon } from '../../icon/icons';
import { SimpleFieldOptions, useFieldProps } from '../field/context';

import { selectStyles } from './select.styles';
import { SelectItem } from './select-item';

type Item = {
    value: string;
    label: string;
    disabled?: boolean;
    icon?: React.ReactNode; // TODO: enforce icon only prop
};

type SelectRootProps = {
    name: string;
    required?: RadixSelect.SelectProps['required'];
    defaultValue?: RadixSelect.SelectProps['defaultValue'];
    value?: RadixSelect.SelectProps['value'];
    disabled?: RadixSelect.SelectProps['disabled'];
    onValueChange?: RadixSelect.SelectProps['onValueChange'];
};

type SelectProps = {
    id?: string;
    options: Item[];
    placeholder?: React.ReactNode;
    hideIndicator?: boolean;
} & TVStyleProps<typeof selectStyles> &
    SelectRootProps &
    SimpleFieldOptions;

const Select = forwardRef<HTMLButtonElement, SelectProps>(function Select(
    {
        name,
        id,
        options,
        placeholder,
        hideIndicator = false,
        classNames,
        fullWidth,
        defaultValue,
        value,
        onValueChange,
        size: sizeProp = 'md',
        variant,
        /** field props */
        readOnly,
        disabled,
        required,
        invalid,
    },
    ref,
) {
    const selectedItem = options.find(opt => opt.value === value) ?? ({} as Item);
    const {
        fieldProps,
        rootProps,
        size = sizeProp,
    } = useFieldProps<HTMLButtonElement>({
        readOnly,
        disabled,
        required,
        invalid,
        id,
    });
    const radixRootProps = {
        name,
        required: fieldProps.required,
        defaultValue,
        value,
        onValueChange,
        disabled: fieldProps.disabled || fieldProps.readOnly,
    };
    const {
        base,
        content,
        scrollDown,
        scrollUp,
        selectedItem: selectedItemStyles,
    } = mapClassNamesToSlots(selectStyles, {
        classNames,
        fullWidth,
        size,
        variant,
    });

    return (
        <RadixSelect.Root {...radixRootProps}>
            <RadixSelect.Trigger id={fieldProps.id} name={name} className={base} {...rootProps} ref={ref}>
                <RadixSelect.Value placeholder={placeholder}>
                    <div className={selectedItemStyles}>
                        {selectedItem.icon ?? null}
                        <p>{selectedItem.label}</p>
                    </div>
                </RadixSelect.Value>
                {hideIndicator ? null : (
                    <RadixSelect.Icon>
                        <ChevronSelectorVerticalIcon className="w-4 h-4 text-surface-interact" />
                    </RadixSelect.Icon>
                )}
            </RadixSelect.Trigger>
            <RadixSelect.Portal>
                <RadixSelect.Content className={content}>
                    <RadixSelect.ScrollUpButton className={scrollUp}>
                        <ChevronUpIcon className="w-4 h-4" />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport className="p-[5px] bg-surface flex flex-col gap-[2px]">
                        {options.map(opt => (
                            <SelectItem
                                key={opt.value}
                                icon={opt.icon}
                                value={opt.value}
                                disabled={opt.disabled}
                                size={size}
                            >
                                {opt.label}
                            </SelectItem>
                        ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className={scrollDown}>
                        <ChevronDownIcon className="w-4 h-4" />
                    </RadixSelect.ScrollDownButton>
                </RadixSelect.Content>
            </RadixSelect.Portal>
        </RadixSelect.Root>
    );
});

export type { SelectProps, SelectRootProps };
export { Select };
